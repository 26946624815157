import { Layout } from 'components/Layout';
import { graphql } from 'gatsby';
import React from 'react';
import { Box } from 'src/components/Box';
import { CheckListItem } from 'src/components/CheckListItem';
import { LandingFeatureGrid } from 'src/components/Landing/LandingFeatureGrid';
import { MediaSection } from 'src/components/MediaSection';
import { MethodsLandingHero } from 'src/components/MethodsLandingHero';
import { OurClientsAlt } from 'src/components/OurClientsAlt/OurClientsAlt';
import { Paragraph } from 'src/components/Paragraph';
import { ScrollingList } from 'src/components/ScrollingList';
import { scrollListItems } from 'src/components/ScrollingList/scrollListItems';
import { ThemeProvider } from 'src/components/ThemeProvider';

export default function NonProfit({ data }) {
    return (
        <Layout
            pageTitleFull="Support your mission with an online community from Audentio"
            siteDescription="Foster connections, create discussions, share resources, monetize, and more with an online community."
            contactForm
            // useLandingNav
        >
            <MethodsLandingHero
                title="Reach your mission with a community"
                video="/videos/targeted/nonprofit/nonprofit-hero.mp4"
                videoPoster="/videos/targeted/nonprofit/nonprofit-hero-poster.jpg"
                videoId="streaming-person-talking"
                subtitle="Foster connections, provide support, and drive your mission forward with a dedicated community."
            />

            <LandingFeatureGrid
                title="A community platform to offer support and help raise funds for your mission."
                items={[
                    {
                        heading: 'provide resources',
                        content:
                            'Offer articles, videos, guided discussion, and more in one convenient place.',
                    },
                    {
                        heading: 'subscriptions and donations',
                        content:
                            'Monetize your way by having subscription tiers, donations, and advertisements.',
                    },
                    {
                        heading: 'paywall premium content',
                        content:
                            'Make content only accessible to subscribers with premium articles and premium-only discussion.',
                    },
                    {
                        heading: 'chat and discussion',
                        content:
                            'Have real-time discussion with members and also member-to-member engagement.',
                    },
                    {
                        heading: 'question and answer',
                        content:
                            'Answers to a question can be voted up or down with the best solution being highlighted.',
                    },
                    {
                        heading: 'leaderboard and awards',
                        content:
                            'Highlight members for their high-level of activity with leaderboard and achievements.',
                    },
                ]}
                mb="0"
            />

            <MediaSection
                title="Why do I need a community platform?"
                pb={[0, 0, 0]}
                flipped
                middleAlign
                customMedia={<ScrollingList items={scrollListItems} />}
            />

            <OurClientsAlt
                title="Communities that trust us"
                clients={[
                    { name: 'IGN', image: data.ign },
                    { name: 'cPanel', image: data.cpanel },
                    { name: 'Yahoo!', image: data.yahoo },
                    { name: 'MacRumors', image: data.macrumors },
                    { name: "Tom's hardware", image: data.tomshardware },
                ]}
            />

            <ThemeProvider defaultMode="dark">
                <MediaSection
                    id="nativeapp"
                    image={data.appsub.childImageSharp.fluid}
                    title="A dedicated native app for your members to engage"
                    backgroundColor="primaryBackground"
                    alt="Dedicated app for non-profit organizations to chat, stream, and share."
                >
                    <Box pt="spacing" as="ul">
                        {[
                            {
                                title: 'Reach your audience where they are ',
                                content:
                                    'Stay in touch with native push notifications on iOS and Android.',
                            },
                            {
                                title: 'Get listed in app stores',
                                content:
                                    'Gain exposure to a wider audience and a younger demographic.',
                            },
                            {
                                title: 'Expand your revenue opportunities',
                                content:
                                    'An avenue for placing mobile ads, app sales, and subscriptions.',
                            },
                        ].map(highlight => (
                            <CheckListItem
                                key={highlight.title}
                                title={highlight.title}
                                as="li"
                            >
                                {highlight.content}
                            </CheckListItem>
                        ))}
                    </Box>
                </MediaSection>
            </ThemeProvider>

            <MediaSection
                id="stream"
                flipped
                image={data.streamingphone.childImageSharp.fluid}
                title="Engage directly with your audience by streaming"
                backgroundColor="secondaryBackground"
                alt="Streaming platform free of third-party branding that supports chat and moderation."
            >
                <Paragraph>
                    Streaming from your own platform gives you more control and
                    the ability to keep engagement on your platform.
                </Paragraph>
                <Box pt="spacing">
                    {[
                        {
                            title: 'Decide your percentage cut',
                        },
                        {
                            title: 'Optional ad-free experience',
                        },
                        {
                            title: 'Chat and moderation',
                        },
                        {
                            title:
                                'Integrate live streaming into your website and community',
                        },
                    ].map(highlight => (
                        <CheckListItem
                            key={highlight.title}
                            title={highlight.title}
                        />
                    ))}
                </Box>
            </MediaSection>
        </Layout>
    );
}

export const query = graphql`
    query NonProfit {
        vbhero: file(
            relativePath: { eq: "images/vbsoftware/talking-point.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        revenuestreams: file(
            relativePath: { eq: "images/xfsoftware/revenue-streams.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        streaminterface: file(
            relativePath: { eq: "images/xfsoftware/stream-interface.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        appsub: file(
            relativePath: { eq: "images/membership/app-subscription.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        streamingphone: file(
            relativePath: { eq: "images/membership/streaming.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        ign: file(relativePath: { eq: "images/clients/ign.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        tomshardware: file(
            relativePath: { eq: "images/clients/toms-hardware.png" }
        ) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        macrumors: file(relativePath: { eq: "images/clients/macrumors.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        yahoo: file(relativePath: { eq: "images/clients/yahoo-sports.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        cpanel: file(relativePath: { eq: "images/clients/cpanel.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
