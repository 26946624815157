// to be used on software & landing pages

export const scrollListItems = [
    { title: 'Have control over your brand' },
    { title: 'Engage with your audience the way you want' },
    { title: "Keep your members' attention" },
    { title: 'Choose how you monetize' },
    { title: 'Control your percentage cut' },
    { title: 'Make your content more discoverable' },
    { title: 'Bring people together on one platform' },
    { title: 'Directly notify your audience' },
    { title: 'Keep data private from big companies' },
    { title: 'Be independent of social media fads' },
    { title: 'Create long-term value' },
];
